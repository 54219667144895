import { useCallback, useMemo } from 'react';

type Props<TSteps extends string> = {
  activeStepIndex: number;
  stepNames: readonly TSteps[];
  onStepChange: (step: TSteps) => void;
};

const WizardStepsIndicator = <TSteps extends string>(props: Props<TSteps>) => {
  const { activeStepIndex, stepNames, onStepChange } = props;

  const inActiveCss = 'bg-white border border-[#ebedef]';
  const activeCss = 'bg-[#ebedef] cursor-pointer';

  const stepCircle = useCallback(
    (index: number, step: TSteps) => {
      const isActive = activeStepIndex >= index;

      return (
        <span
          onClick={() => isActive && onStepChange(step)}
          className={`mx-2 my-6 flex h-[32px] w-[32px] flex-shrink-0 items-center justify-center rounded-full ${
            isActive ? activeCss : inActiveCss
          } text-dpm-14 font-medium text-[#40464f]`}
        >
          {index + 1}
        </span>
      );
    },
    [activeStepIndex, onStepChange],
  );

  const stepsWithLines = useMemo(() => {
    const steps = stepNames.map((x, i) => stepCircle(i, x));
    const stepsWithLines = steps.reduce((acc, curr, i) => {
      if (i > 0) {
        acc.push(<div className="bg-gray-4 h-px flex-grow" />);
      }
      acc.push(curr);

      return acc;
    }, [] as JSX.Element[]);

    return stepsWithLines.map((x, i) => (
      <li key={i} className={`${i % 2 === 0 ? 'flex-shrink' : 'flex-1'}`}>
        <div className={`flex h-[64px] items-center justify-center no-underline focus:outline-none`}>{x}</div>
      </li>
    ));
  }, [stepCircle, stepNames]);

  return (
    <ul className="relative m-0 flex list-none justify-between overflow-hidden p-0 transition-[height] duration-200 ease-in-out">{stepsWithLines}</ul>
  );
};

export default WizardStepsIndicator;
